import React, { useState, useEffect } from "react";
import "./App.css";

function App() {
  const [content, setContent] = useState(
    '<span class="highlight">mohan@mohans-MacBook-Pro</span> % '
  );
  const [currentCharIndex, setCurrentCharIndex] = useState(0);
  const [currentCommandIndex, setCurrentCommandIndex] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isExecuting, setIsExecuting] = useState(false);

  const commands = [
    "name",
    "Mohan",
    "role",
    "Backend And Smart Contract Developer",
    "skills",
    `Javascript/typescript , Rust , Move`,
    "Blockchains worked",
    "Solana , Aptos , Sui",
    "Current Status",
    "Developer@kanalabs",
    "github-id",
    '<a href="https://github.com/mohan00710" target="_blank" rel="noopener noreferrer" class="command-link">mohan00710 click to redirect</a>',
    "twitter-id",
    '<a href="https://twitter.com/mohan0799" target="_blank" rel="noopener noreferrer" class="command-link">mohan0799 click to redirect</a>',
  ];

  useEffect(() => {
    if (currentCommandIndex < commands.length) {
      const currentCommand = commands[currentCommandIndex];

      const typeInterval = setInterval(() => {
        if (currentCharIndex < currentCommand.length) {
          setContent(
            (prevContent) => prevContent + currentCommand[currentCharIndex]
          );
          setCurrentCharIndex((prevCharIndex) => prevCharIndex + 1);
        } else {
          clearInterval(typeInterval);

          if (currentCommandIndex % 2 === 0) {
            // If current line is a command
            setContent((prevContent) => prevContent + "\n");
          } else {
            // If current line is an output
            setContent(
              (prevContent) =>
                `${prevContent}\n<span class="highlight">mohan@mohans-MacBook-Pro</span> % `
            );
          }

          setCurrentCommandIndex((prevCommandIndex) => prevCommandIndex + 1);
          setCurrentCharIndex(0);
        }
      }, 100);

      // Cleanup function to clear the interval when the component is unmounted
      return () => clearInterval(typeInterval);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCommandIndex, currentCharIndex, content]);

  // const handleExecute = () => {
  //   setIsExecuting(true);
  // };

  const handleTerminate = () => {
    setIsExecuting(false);
    setContent("mohan@mohans-MacBook-Pro % ");
    setCurrentCommandIndex(0);
    setCurrentCharIndex(0);
  };

  return (
    <div className="App">
      <div className="console">
        <div className="console-header">
          <div className="console-title">
            <div className="red"></div>
            <div className="yellow"></div>
            <div className="green"></div>
            profile.sh
          </div>
          <div className="console-actions">— ☐ ✖</div>
        </div>
        <div className="console-body">
          <pre dangerouslySetInnerHTML={{ __html: content }}></pre>
        </div>
      </div>
      <div className="console-buttons">
        {" "}
        {/* Moved this div outside of the console div */}
        <button className="button terminate" onClick={handleTerminate}>
          Restart
        </button>
      </div>
      <div className="development-label">Under Development</div>  {/* New div */}
    </div>
  );
}

export default App;
